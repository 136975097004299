import React from 'react';
import { IntlProvider, FormattedMessage } from 'react-intl';
import { graphql } from 'gatsby';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Layout from '../components/layout';
import ContentWrapper from '../components/ContentWrapper';
import DownloadBadge from '../components/DownloadBadge';

import getMessages from '../locales/getMessages';

const messages = getMessages();

const HowToTemplate = ({ data, pageContext }) => {
  const { markdownRemark, site } = data; // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark;
  const {
    locale, title, path, description,
  } = frontmatter;
  const { url } = site.siteMetadata;

  return (
    <Layout
      pageContext={pageContext}
      seo={{
        title: `${title} - EverTool`,
        subtitle: description,
        url: `${url}${path}`,
        hreflangs: pageContext.hreflangs,
        locale,
      }}
    >
      <IntlProvider locale={locale} messages={messages[locale]} textComponent={React.Fragment}>
        <div>
          <Header />
          <ContentWrapper isMarkdown>
            <div className="blog-post">
              <h1>{title}</h1>
              {/* <small>{date}</small> */}
              <hr />
              <div
                className="blog-post-content mb5"
                dangerouslySetInnerHTML={{ __html: html }}
              />
              <hr />
              <div className="center">
                <p className="is-size-6 has-text-centered mb3 mt4">
                  <FormattedMessage
                    id="intro.congrat"
                    defaultMessage="Congrats! You can start to customize your font style collection."
                  />
                </p>
                <DownloadBadge />
              </div>
            </div>
          </ContentWrapper>
        </div>
        <Footer />
      </IntlProvider>
    </Layout>
  );
};

export default HowToTemplate;

export const pageQuery = graphql`
  query HowToByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        description
        locale
      }
    }
    site {
      siteMetadata {
        title
        subtitle
        url
      }
    }
  }
`;
